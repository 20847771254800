import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Pricing = () => (
  <Section id="pricing">
    <StyledContainer>
      <Subtitle>Pricing</Subtitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Starter – $99/mo</FeatureTitle>
            <ul>
              <li>1 Seat</li>
              <li>Local Insights</li>
              <li>Company Product Explainer</li>
              <li>Website NLP Summary</li>
              <li>Social Media Usage Roundup</li>
              <li>SFDC Plugin</li>
              <li>Web Access</li>
              <li>Help documentation</li>
            </ul>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Enterprise – Contact us</FeatureTitle>
            <ul>
              <li><b>Unlimited Seats</b></li>
              <li>Local Insights</li>
              <li>Company Product Explainer</li>
              <li>Website NLP Summary</li>
              <li>Social Media Usage Roundup</li>
              <li>SFDC Plugin</li>
              <li>Web Access</li>
              <li><b>Email Support</b></li>
            </ul>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Pricing

const StyledContainer = styled(Container)``

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`


import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"

const Product = () => (
  <StyledSection id="product">
    <Subtitle>Product</Subtitle>
    <GetStartedContainer>
      <GetStartedTitle>We use AI, machine learning, and NLP to help you know...</GetStartedTitle>
      <p>
        That the lead you are about to follow up on is John in Accounting at High Pine's Scrub Inc.
        He lives in Denver, Colorado where the skies are clear and it is a chilly 37º.
        The latest local hoopla is whether of not a few historic buildings will get demolished for new development.
        His employer produces CDB infused loofah wash and pinecone loofah brushes.
        They've also been in the local news lately because they are expanding quickly and
        plan on opening up additional manufacturing capacity outside city limits.
      </p>
    </GetStartedContainer>
  </StyledSection>
)

export default Product

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% calc(100% - 5vw));
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`


const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`